<template>
  <div class="flex flex-col">
    <Title :pages="pages">
    </Title>

    <div v-if="loading">loading....</div>
    <div v-else-if="workflow" class="flex-1 flex flex-col">
      <div class="py-3 px-4 sm:px-6 lg:px-8">
        <Card>
          <CardItem
            heading="Name"
            :icon="CalendarIcon"
            :message="workflow.name"
          >
            <template #secondary-heading>
              <p
                :class="{
                  'bg-green-100 text-green-800':
                    workflow.status === 'Published',
                  'bg-gray-100 text-gray-800': workflow.status === 'Draft',
                  'bg-blue-100 text-blue-800': workflow.status === 'Processing',
                  'bg-red-100 text-red-800':
                    workflow.status === 'ProcessFaileding',
                }"
                class="
                  px-2
                  inline-flex
                  text-xs
                  leading-5
                  font-semibold
                  rounded-full
                "
              >
                {{ workflow.status }}
              </p>
            </template>

            <template #secondary-message>
              <Timestamp :value="workflow.created_at" />
            </template>
          </CardItem>

          <CardItem heading="Owner" :message="workflow.user?.name" />
          <CardItem heading="Last updated">
            <Timestamp :value="workflow.updated_at" />
          </CardItem>
        </Card>
      </div>

      <div v-if="workflow?.status === 'Published'">
        <ResourceTable
          ref="logs"
          :columns="columns"
          :options="resourceTableOptions"
          :url="`workflows/${workflow._id}/logs`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, inject, computed } from "vue";
import { CalendarIcon } from "@heroicons/vue/solid";
import { useRoute } from "vue-router";
import card from "@/components/common/card";
import Title from "@/components/common/Title";
// import Button from "@/components/common/Button";
import Timestamp from "@/components/common/Timestamp";
import ResourceTable from "@/components/common/resources/Table";
import { logColumns as columns } from "@/views/workflows/columns";

export default defineComponent({
  mixins: [card],

  components: {
    Title,
    Timestamp,
    ResourceTable,
  },
  setup() {
    const route = useRoute();
    const $axios = inject("axios");

    const loading = ref(true);
    const workflow = ref(null);

    const publish = usePublish(route.params.workflow);

    const pages = computed(() => [
      {
        name: "Workflows",
        href: "workflows.index",
        current: false,
      },
      {
        name: workflow.value ? `${workflow.value.name}` : "View",
        href: "workflows.view",
        current: true,
      },
    ]);

    (async function () {
      loading.value = true;

      try {
        const [{ data: item }] = await Promise.all([
          $axios.get(`/workflows/${route.params.workflow}`),
        ]);

        workflow.value = item.data;
      } catch (error) {
        //
      }

      loading.value = false;
    })();

    return {
      ...publish,
      CalendarIcon,
      columns,
      pages,
      loading,
      workflow,
      resourceTableOptions: {
        viewRoute: (item) => ({
          name: "workflows.logs.view",
          target: "_blank",
          params: { workflow: route.params.workflow, run_id: item.meta.run_id },
        }),
      },
    };
  },
});

function usePublish(workflowId) {
  const axios = inject("axios");

  const publishing = ref(false);

  async function handlePublish() {
    publishing.value = true;

    try {
      await axios.post(`/workflows/${workflowId}/publish`);
    } catch (error) {
      //
    }

    publishing.value = false;
  }

  return {
    publishing,
    handlePublish,
  };
}
</script>
